import moment from 'moment'
import Vars from 'Consts/vars'
import Env from 'Consts/env'
import Axios from "axios";

export const filterSeoDesc = (text) => {
  const filter = ['%%title%%', '%%page%%', '%%sep%%', '%%sitename%%']
  const tmp = text.split(' ').filter((item) => !filter.includes(item))
  return tmp.toString().replace(/[,\s]/g, ' ')
}

export const injectAltImage = (query, value) => {
  const btnClosetop = document.querySelector('.' + query)
  !!btnClosetop ? btnClosetop.setAttribute('alt', value) : null
}

export const image_src = ({ image }) => {
  const img = image ? image.split('://') : []
  return image
    ? img.length > 1
      ? '//' + img[1]
      : image
    : '//static.femaledaily.com/dyn/400/images/user-pics/placeholder_user.png'
}

export async function ads_detik(site, params) {
  const urls =
    ["canary", "production"].includes(process.env.NEXT_PUBLIC_NODE_ENV)
      ? 'https://detikads.femaledaily.com/static/ads/femaledaily/'
      : 'https://detikads.femaledaily.com/static/ads/femaledaily/staging_femaledaily/'
  const res = await fetch(urls + site + '/' + params + '.txt')

  return await res.text()
}

export function ScrollToUp() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}


export function scrollToId(params) {
  document.getElementById(params).scrollIntoView({
    behavior: 'auto'
  });
}

export const stringUrl = (params) => {
  let tmp = Array.isArray(params)
    ? params[params.length - 1].replace(/[-]/g, ' ')
    : params.replace(/[-]/g, ' ')
  return tmp
}

export const slugUrl = (params) => {
  let tmp = Array.isArray(params) ? params[params.length - 1] : params
  return tmp
}

export const categoryName = (slug, categories) => {
  let slugs = Array.isArray(slug) ? slug[slug.length - 1] : slug,
  tmp = categories.filter(
    (item) => item.slug.replace(/[_]/g, '-') === slugs.toLowerCase(),
    )[0].name
  return tmp
}

export const countComment = (comment) => {
  let count = 0
  for (const commments of comment) {
    if (commments.childs.length === 0) {
      count += 1
    } else {
      count += commments.childs.length
      count += 1
    }
  }
  return count
}

export async function fetcher(...args) {
  const res = await fetch(...args)
  return res.json()
}

export const moment_date = ({ date }) => {
  // const local_date = moment(moment.utc(date).toDate())
  // .local()
  const local_date = moment(date).format('YYYY-MM-DD HH:mm:ss'),
    res = moment(local_date).add(7, 'days').isBefore(moment())
      ? moment(local_date).format('DD MMM YYYY')
      : moment(local_date).fromNow()
  return res
}


export const momentDateSynergy = ({ date, type }) => {

	const localDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
	const res = type === "mobile" ? moment(localDate).format("DD MMMM YYYY") : moment(localDate).format("DD MMM");
	return res;

};

export const setGoogleTags = () => {
  return {
    __html: `
	   /* <![CDATA[ */
	  var wpvq_dont_use_FBAPI = "false";
	  var wpvq_API_already_loaded = "true";
	  var wpvq_facebookAppID = "";
	  var wpvq_forceFacebookShare = "false";
	  /* ]]> */

	  `,
  }
}

export const setAjaxWpvq = () => {
  return {
    __html: `
	  /* <![CDATA[ */
		var ajaxurl = "/api/quiz";
		var wpvq_imgdir = "${
      ["canary", "production"].includes(process.env.NEXT_PUBLIC_NODE_ENV)
        ? 'https://editorial.femaledaily.com/wp-content/plugins/wp-viral-quiz/views/img/'
        : 'https://editorial.femaledaily.net/wp-content/plugins/wp-viral-quiz/views/img/'
    }";
		var wpvq_i18n_loading_label = "Loading";
		/* ]]> */

	  `,
  }
}

export const setGTM = (props) => {
  return {
    __html: `
	  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
	  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
	  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
	  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
	  })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
	  function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${process.env.NEXT_PUBLIC_GTM_ID}');
	  `,
  }
}

export const setGTM_2 = (props) => {
  return {
    __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		'//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
		})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID2}');

		function gtag(){dataLayer.push(arguments);}
		gtag('js', new Date());
		gtag('config', '${process.env.NEXT_PUBLIC_GTM_ID2}');`,
  }
}

export const fbsdk = () => {
  return {
    __html: `
	(function(d, s, id){
		 var js, fjs = d.getElementsByTagName(s)[0];
		 if (d.getElementById(id)) {return;}
		 js = d.createElement(s); js.id = id;
		 js.src = "//connect.facebook.net/en_US/sdk.js";
		 fjs.parentNode.insertBefore(js, fjs);
	}(document, 'script', 'facebook-jssdk'));
`,
  }
}

export const fbpixel = () => {
  return {
    __html: `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '301171411550437');
    fbq('track', 'PageView');`,
  }
}

export const comSocre = () => {
  return {
    __html: `
	  var _comscore = _comscore || [];
	  _comscore.push({ c1: "2", c2: "8443234" });
	  (function() {
		var s = document.createElement("script"), el = document.getElementsByTagName("script")[0]; s.async = true;
		s.src = (document.location.protocol == "https:" ? "https://sb" : "http://b") + ".scorecardresearch.com/beacon.js";
		el.parentNode.insertBefore(s, el);
	  })();
  `,
  }
}

export const setGoogleAnalytics = () => {
  return {
    __html: `
	(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
		(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
		m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
	})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

	ga('create', '${process.env.NEXT_PUBLIC_GA_GENERAL_ID}', 'auto', 'fdn');
	ga('fdn.send', 'pageview');
	`,
  }
}

export const setGoogleAnalytics_ = () => {
  return {
    __html: `
	  var _gaq = _gaq || [];

	  _gaq.push(['fdn._setAccount', '${process.env.NEXT_PUBLIC_GA_GENERAL_ID}']);
	  _gaq.push(['fdn._setDomainName', 'femaledaily.com']);
	  _gaq.push(['fdn._setAllowLinker', true]);
	  _gaq.push(['fdn._trackPageview']);

	  (function() {
		var ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;
		ga.src = ('https:' == document.location.protocol ? 'https://ssl' : 'http://www') + '.google-analytics.com/ga.js';
		var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);
	  })();
	  `,
  }
}

export const setGoogleAnalyticsNoscript = () => {
  return {
    __html: `
	<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GA_GENERAL_ID}"
	height="0" width="0" style="display:none;visibility:hidden"></iframe>
	`,
  }
}

export const setGoogleAnalyticsNoscript_ = () => {
  return {
    __html: `
	  <iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GA_HOST_ID}"
	  height="0" width="0" style="display:none;visibility:hidden"></iframe>
	  `,
  }
}

export async function fetchNavmenu() {
  const resNavMenu = await fetch(
    process.env.NEXT_PUBLIC_ROOT_DOMAIN + '/api/navmenu',
    { headers: { ...Env.HEADERS } },
  )

  try {
    const getNavMenu = await resNavMenu.json()

    let dataNavMenu = Vars.mega_menu

    if (typeof getNavMenu !== 'undefined') {
      dataNavMenu.filter((dataMenu) => dataMenu.name === 'TOPICS')[0].children =
        []

      Array.isArray(getNavMenu.data)
        ? getNavMenu.data.map(
            (
              menu, // push dynamic menu data to static menu
            ) =>
              dataNavMenu
                .filter((dataMenu) => dataMenu.name === 'TOPICS')[0]
                .children.push({
                  name: menu.name,
                  url: menu.url,
                  slug: menu.slug,
                  id: `id_${menu.slug}_home`,
                  children: menu.children,
                }),
          )
        : []

      dataNavMenu //customize menu to include static image
        .filter((dataMenu) => dataMenu.name === 'TOPICS')[0]
        .children.filter((child) => child.slug === 'fdlocalpower')[0].children =
        [
          {
            image:
              'https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/local+power.png',
            url:
              process.env.NEXT_PUBLIC_ROOT_DOMAIN + '/blog/tag/fdlocalpower/',
          },
        ]
    }
    return dataNavMenu
  } catch (err) {
    return []
  }
}



export const Rupiah = (props) => {
  const reverse = props.toString().split('').reverse().join('')
  const ribuan = reverse.match(/\d{1,3}/g)
  const final = ribuan.join('.').split('').reverse().join('')

  return `Rp. ${final}`
}

export const ThousandSeparator = (props) => {
  const reverse = props.toString().split('').reverse().join('')
  const ribuan = reverse.match(/\d{1,3}/g)
  const final = ribuan.join('.').split('').reverse().join('')

  return final;
}

export const setVideoWidget = () => ({
  __html: `
			var mobile = Boolean(window.navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i));

      var bottomAds = !!document.getElementById("sb_mobile_sticky")

			Video.config({
				isMobile: mobile,
				unique_code: "fd2kale",
				selector: "#vidwidget",
        domain2: "${process.env.NEXT_PUBLIC_ROOT_DOMAIN}",
				isAds: bottomAds,
				position: "fixed",
        elementId: "videoWidgetStreaming",
			});

		Video.widgets.render();
		`,
})

export const truncate_text = (text, maxchar) => {
  let truncated = text

  if (truncated.length > maxchar) {
    truncated = truncated.substr(0, maxchar) + ' ... '
  }
  return truncated
}

export const handleUrlArticle = (url, params) => {
  let res = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/blog${url.split('blog')[1]}`
  return res
}

export const setDataLayer = (dataObj, urlTarget) => {

	if (urlTarget === "GTM") {

		const dataLayer = window.dataLayer || [];

		if (typeof dataObj === "object" && dataObj !== null) {

			dataLayer.push(dataObj);

		}

	}

};

export const getReferrerType = (referrer) => {
  const lowerReferrer = referrer.toLowerCase();

  if (lowerReferrer.match("search")) {
    return "fromsearch";
  } else if (lowerReferrer.match("editorial")) {
    return "fromeditorial";
  } else if (lowerReferrer.match("reviews")) {
    return "fromfdbr";
  } else if (lowerReferrer.match("femaledaily")) {
    return "fromhome";
  } else {
    return lowerReferrer;
  }
};

export const Fetching = async (params) => {

	try {

		const result = await Axios({
			method: params.method,
			url: `/api/${params.url}/`,
			data: params.data,
			headers: { ...Env.HEADERS },
		});

		return result;

	} catch (error) {

		throw new Error("Error response");

	}

};

export const imageDynamic = (url, width) => {

	if (url.match(/assets.femaledaily.com/g)) {

		return (
			`https://image.femaledaily.com/dyn/${
				width
			}${url.split("assets.femaledaily.com")[1]}`
		);

	}
	return url;

};